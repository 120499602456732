<template>
  <div class="nav-search noselect" :style="{ height: `${viewportHeight}px` }">
    <div
      class="nav-search__search-container"
      :class="{ 'nav-search__search-container--active': active }"
      ref="search"
    >
      <div
        class="nav-search__search-background"
        :class="{ 'nav-search__search-background--active': active }"
        @click="() => handleActiveControl(false)"
      ></div>
      <NavigationSearchInterface :active="active" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"

import NavigationSearchInterface from "./NavigationSearchInterface.vue"

export default {
  props: {
    active: Boolean
  },

  components: {
    NavigationSearchInterface
  },

  methods: {
    ...mapMutations("ui", ["SET_ROUTE_TRANSITION_STATE"]),
    ...mapActions("identity", ["SIGN_OUT"]),

    handleActiveControl(val) {
      this.$emit("activated", val)
    }
  },

  computed: {
    ...mapGetters("breakpoints", [
      "GET_IS_BELOW_BREAKPOINT",
      "GET_IS_ABOVE_BREAKPOINT"
    ]),
    ...mapState("identity", ["authJWT"]),
    ...mapState("scroll", ["viewportHeight"])
  }
}
</script>

<style lang="scss">
.nav-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__search-container {
    position: absolute;
    top: $nav-height-default;
    left: 0;
    width: 100%;
    height: calc(100% - #{$nav-height-default});

    @include media-breakpoint-up(md) {
      top: $nav-height-md;
      height: calc(100% - #{$nav-height-md});
    }

    @include media-breakpoint-up(lg) {
      top: $nav-height-lg;
      height: calc(100% - #{$nav-height-lg});
    }

    @include media-breakpoint-up(xl) {
      top: $nav-height-xl;
      height: calc(100% - #{$nav-height-xl});
    }

    &--active {
      touch-action: none;
    }
  }

  &__search-background {
    background-color: rgba($bleach-black, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
